body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import '~react-image-gallery/styles/css/image-gallery.css';
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mr-label {
  margin-right: 10px;
}

.btn {
  border-radius: 2.5px;
}

/* Css for preloader */
.loader {
  width: 160px;
  height: 160px;
  margin: 50px auto;
  position: relative;
}
.loader .box {
  background-color: rgba(0, 0, 0, 0.04);
  border: 4px solid transparent;
  border-left: 4px solid #14baff;
  border-top: 4px solid #14baff;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: animate1 3s linear infinite;
}
.loader .box:nth-of-type(2) {
  background-color: rgba(0, 0, 0, 0.025);
  border: 4px solid transparent;
  border-right: 4px solid #fff;
  border-bottom: 4px solid #fff;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  animation: animate1 3s reverse linear infinite;
}
.loader .box .circle {
  transform-origin: left;
  position: absolute;
  top: calc(50% - 1px);
  left: 50%;
  width: 50%;
  height: 2px;
}
.loader .box .circle,
.loader .box:nth-of-type(2) .circle {
  transform: rotate(-45deg);
}
.loader .box .circle:before {
  content: '';
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  right: -6px;
}
.loader .box .circle:before {
  background: #14baff;
  box-shadow: 0 0 20px #14baff, 0 0 40px #14baff, 0 0 60px #14baff,
    0 0 80px #14baff, 0 0 100px #14baff, 0 0 0 5px #14baff;
}
.loader .box:nth-of-type(2) .circle:before {
  background: #fff;
  box-shadow: 0 0 20px #fff, 0 0 40px #fff, 0 0 60px #fff, 0 0 80px #fff,
    0 0 100px #fff, 0 0 0 5px #fff;
}
@keyframes animate1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.marginIsAMust {
  margin: 20px !important;
}

.img-responsive-custom {
  margin: 1em 0;
  text-align: center;
}

.img-responsive-custom img {
  border-radius: 1.5px;
  height: 200px;
  object-fit: cover;
}

.img-responsive-custom-1 img {
  width: 150px;
  height: 120px;
  border-radius: 2.5px;
  object-fit: cover;
}

.img-responsive-custom-1 img {
  width: 150px;
  height: 150px;
  border-radius: 2.5px;
  object-fit: cover;
}

.img-responsive-custom-2 img {
  width: 40px;
  height: 30px;
  object-fit: contain;
}

.transition-enter {
  opacity: 0;
  transform: scale(0.9);
}

.transition-enter-active {
  opacity: 1;
  transform: translate(0);
  transition: opacity 300ms, transform 300ms;
}

.transition-exit {
  opacity: 1;
}

.transition-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.mt-15 {
  margin-top: 15px !important;
}
