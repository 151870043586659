* {
  font-family: "Roboto Condensed", sans-serif;
}
.navigation-bar {
  background-color: #fff;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  position: sticky;
  top: 0;
  z-index: 50;
  box-shadow: 0 6px 9px 0 rgba(36, 39, 44, 0.2);
}

.navigation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
  background: #fff;
}

.navigation-container ul {
  margin-bottom: 0px !important;
}

.navigation-logo {
  color: #14baff;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  flex-grow: 1;
}

.navigation-logo img {
  width: 200px;
  object-fit: contain;
  height: 100px;
}

.navigation-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.navigation-links {
  color: #000;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.fa-code {
  margin-left: 1rem;
}

.navigation-item {
  line-height: 40px;
  margin-right: 1rem;
}

.navigation-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.navigation-item:hover:after {
  width: 100%;
  background: #14baff !important;
}

.navigation-item .active {
  color: #14baff;
  font-weight: 400;
}

.navigation-icon {
  display: none;
  margin-top: 7px;
}

@media screen and (max-width: 960px) {
  .logo-brand .brand-title h2 {
    width: 100%;
  }
  .navigation-item .active {
    margin-top: 15px;
  }
  .navigation-menu {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .navigation-menu.active {
    background: #fff;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .navigation-item .active {
    color: #14baff;
    font-weight: 400;
  }

  .navigation-links {
    color: #000;
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .navigation-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #14baff;
  }
}
