#progressbar {
  margin: 10px auto;
  overflow: hidden;
  color: lightgrey;
  width: fit-content;
}

#progressbar li:before .active {
  color: #000000 !important;
}

#progressbar li {
  list-style-type: none;
  font-size: 12px;
  display: inline-block;
  margin: 0 10px;
  position: relative;
  cursor: pointer;
}

#progressbar #account:before {
  font-family: FontAwesome;
  content: '\f02d';
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: '\f007';
}

#progressbar #payment:before {
  font-family: FontAwesome;
  content: '\f09d';
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: '\f00c';
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  font-size: 16px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0px;
  text-align: center;
}

#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: #14baff;
}
